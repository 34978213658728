<template>
  <div
    class="tooltip"
    @click="setIsContentDisplayed(!isContentDisplayed)"
    @mouseenter="setIsContentDisplayed(true)"
    @mouseleave="setIsContentDisplayed(false)"
  >
    <InfoIcon
      :variant="iconVariant"
      :size="16"
    />
    <transition name="fade">
      <div
        v-if="isContentDisplayed"
        class="tooltip__body"
      >
        <p class="tooltip__body__content">
          {{ content }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import InfoIcon from '@/core/components/shared/icons/Info.icon.vue'

export default defineNuxtComponent({
  components: {
    InfoIcon,
  },
  props: {
    content: {
      type: String,
      default: () => '',
    },
    iconVariant: {
      type: String,
      default: 'yellow', // yellow, semi-light, light, dark
    },
  },
  setup() {
    const isContentDisplayed = ref(false)
    function setIsContentDisplayed(value: boolean) {
      isContentDisplayed.value = value
    }

    return {
      isContentDisplayed,
      setIsContentDisplayed
    }
  },
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  @apply opacity-100;
  @apply transition-all duration-200;
}
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
  @apply mt-10p;
}

.tooltip {
  @apply relative;
  &__body {
    @apply absolute;

    // top: -40px;
    z-index: 10;

    @apply border border-spl-gray-2;
    @apply rounded-md;
    @apply bg-white;

    @apply font-bold;

    min-width: 400px;

    &__content {
      @apply px-10p py-5p;
    }
  }

  &__arrow {
    @apply border border-spl-gray-1;
    @apply border-t-0 border-l-0;
    @apply w-12p h-12p;
    @apply top-30p left-1/2;
    @apply transform rotate-45;
    @apply bg-white;
    @apply z-10;
  }
}
</style>
